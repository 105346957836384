import React from "react"
import { Link } from "gatsby"
import "../tachyons.min.css"

const Breadcrumbs = (props) => {
	const list = props.items;
	const crumbs = list.map((item, idx) =>
		<li className='dib' key={ item.url }>
			<Link className='black-40 no-underline dim' to={ item.url + "/" }>{ item.vanity }</Link>
			{ (idx+1) < list.length &&
				<span className='black-40 ph2'>/</span>
			}
		</li>
	)
	return (
		<ol className='pa0 ma0 list pt0 pt2-ns'>
			{ crumbs }
		</ol>
	)
}

export default Breadcrumbs;
