import React from "react"
import { graphql, Link } from "gatsby"
import cart from "../lib/cart"
import events from "../lib/events"
import { messages } from "../lib/messages"
import Layout from "../components/layout"
import Breadcrumbs from "../components/breadcrumbs"
import Thumbnails from "../components/thumbnails"
import ProductImage from "../components/product-image"
import { FreeShippingSlim } from "../components/free-shipping"
import Icon from "../components/icon"
import { FormSelect, FormInput, FormTextArea, FormList, FormCourse, FormLabel } from "../components/forms"
import { BlackButton } from "../components/black-buttons"
import _snake from "lodash/snakeCase"
import _find from "lodash/find"
import _includes from "lodash/includes";


const ACRYLIC_MAP = {
	'Mirrored Gold': 'premium_price',
	'Mirrored Rose Gold': 'premium_price',
	'Mirrored Silver': 'premium_price',
	'White': 'base_price',
	'Black': 'base_price',
}

const WOOD_MAP = {
	'Gold': 'premium_price',
	'Rose Gold': 'premium_price',
	'Silver': 'premium_price',
	'White': 'premium_price',
	'Black': 'premium_price',
	'Natural Wood': 'base_price'
}

const buildList = (list) => {
	return list.map(item => { return { value: item, label: item } })
}

const isPremium = (material, color) => {
	const map = material === "Wood" ? WOOD_MAP : ACRYLIC_MAP;
	return map[color] === 'premium_price';
}

const default_states = {
	'cake_toppers': {
		quantity: 1,
		color: 'Choose Color',
		customization: ''
	},
	'signs': {
		quantity: 1,
		color: 'Choose Color',
		customization: ''
	},
	'drink_stirrers': {
		quantity: 1,
		color: 'Choose Color',
		size: 'Tall'
	},
	'menus': {
		quantity: 5,
		color: 'Choose Color',
		first: '',
		second: '',
		third: ''
	},
	'place_cards': {
		color: 'Choose Color',
		quantity: 1,
		names: []
	},
	'table_numbers': {
		color: 'Choose Color',
		quantity: 1,
		starting_number: 1,
		ending_number: 15
	}
}

const ProductDescription = (props) => (
	<div className='mb3'>
		<h2 className='ma0 pa0 arvo f3 f2-ns fw4'>{props.product.name}</h2>
		{ props.description }
		<p className='pv2 f4'>${ props.price } { props.price_suffix }</p>
		{ props.children }
	</div>
)


class SignForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...default_states['signs']
		};
	}
	getPrice = () => {
		const { color } = this.state;
		const product = this.props.product;
		if(isPremium(product.material, color)) {
			return product.premium_price;
		}
		return product.base_price;
	}
	updateField = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}
	valid = () => {
		const color = this.state.color;
		if(this.props.product.customizable) {
			return color !== "Choose Color" && this.state.customization.length >= 2;
		}
		return color !== "Choose Color";
	}
	addToCart = (e) => {
		e.preventDefault();
		if(this.valid()) {
			const product = {
				material: this.props.product.material,
				customization: this.state.customization,
				color: this.state.color,
				quantity: this.state.quantity,
				price: this.getPrice(),
				comparator: cart.uuid()
			}
			// product.comparator = _snake(this.props.product.name + this.state.material + this.state.color + this.state.names[0] + this.state.names[this.state.names.length - 1]);
			this.props.add(product);
			this.clearForm();
		} else {
			messages.show('Please select a quantity and color before adding this to your cart.');
		}
	}
	clearForm = () => {
		this.setState({
			names: []
		})
	}
	render() {
		const product = this.props.product;
		const quantity = this.state.quantity;
		const colors = product.colors;
		return (
			<div>
				<ProductDescription product={ product } price={ this.getPrice() }>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Dimensions</h3>
						<p className='pa0 ma0'>{ product.dimensions_string }</p>
					</div>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Material</h3>
						<p className='pa0 ma0'>1/4" { product.material }</p>
					</div>
				</ProductDescription>
				<div className='bt b--black-05 pt4 mb4 mb0-ns'>
					<form>
						<div className='w-100 w-50-ns db'>
							<FormSelect name='color' label="Color" items={ [{ value: 'Choose Color', label: 'Choose Color' }].concat( buildList(colors) ) } value={ this.state.color } onChange={ this.updateField } />
						</div>
						{ product.customizable &&
							<FormTextArea desc={ product.instructions } name='customization' label="Custom Name(s)" value={ this.state.customization } onChange={ this.updateField } />
						}
						<div className='cf'>
							<div className='w-100 w-20-ns fl'>
								<FormInput name='quantity' label="Quantity" value={ this.state.quantity } onChange={ this.updateQuantity } />
							</div>
						</div>
						<div className='tr'>
							<span className={ 'mb3 black-50 fadeinUp ' + ( this.state.color === 'Choose Color' ? ' dn' : ' db') } key={ quantity }>Subtotal: ${ this.getPrice() * quantity }</span>
							<BlackButton title="Add to Cart" disabled={ !this.valid() } onClick={ this.addToCart } />
						</div>
					</form>
				</div>
			</div>
		)
	}
}


class MenuForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...default_states['menus']
		};
	}
	getPrice = () => {
		return this.props.product.base_price;
	}
	updateField = (event) => {
		console.log(event.target.name, event.target.value);
		this.setState({
			[event.target.name]: event.target.value,
		});
	}
	updateQuantity = (event) => {
		let quantity = parseInt(event.target.value);
		if(isNaN(quantity)) {
		  quantity = 5;
		}
		this.setState({ quantity: quantity })
	}
	valid = () => {
		let { first, second, third, quantity } = this.state;
		return first.length > 0 && second.length > 0 && third.length > 0 && quantity >= 5;
	}
	addToCart = (e) => {
		e.preventDefault();
		if(this.valid()) {
			const product = {
				material: this.props.product.material,
				color: this.props.product.colors[0],
				first: this.state.first,
				second: this.state.second,
				third: this.state.third,
				quantity: this.state.quantity,
				price: this.getPrice(),
				comparator: cart.uuid()
			}
			// product.comparator = _snake(this.props.product.name + this.state.material + this.state.color + this.state.names[0] + this.state.names[this.state.names.length - 1]);
			this.props.add(product);
			this.clearForm();
		} else {
			messages.show('Please select a quantity and color before adding this to your cart.');
		}
	}
	clearForm = () => {
		this.setState({
			names: []
		})
	}
	render() {
		const product = this.props.product;
		const quantity = this.state.quantity;
		return (
			<div>
				<ProductDescription product={ product } price={ this.getPrice() }>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Dimensions</h3>
						<p className='pa0 ma0'>{ product.dimensions_string }</p>
					</div>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Material</h3>
						<p className='pa0 ma0'>1/8" Acrylic</p>
					</div>
				</ProductDescription>
				<div className='bt b--black-05 pt2 mb4 mb0-ns'>
					<p className='fw4 f6 black-80'>Enter the lines for each section exactly as you want them to appear. For all menu orders, we send you a digital proof. Read our <Link className='blue no-underline hover-near-black' to="/faq">proof policy</Link> for more information.</p>
					<form className='pt3'>
						<div className='cf mb1'>
							<FormCourse label={ 'First Course' } name="first" value={ this.state.first } onChange={ this.updateField } minHeight={100} />
						</div>
						<div className='cf mb1'>
							<FormCourse label={ 'Second Course' } name="second" value={ this.state.second } onChange={ this.updateField } minHeight={100} />
						</div>
						<div className='cf mb1'>
							<FormCourse label={ 'Dessert' } name="third" value={ this.state.third } onChange={ this.updateField } minHeight={100} />
						</div>
						<div className=''>
							<div className='w-30 w-20-ns'>
								<FormInput name='quantity' label="Quantity" value={ this.state.quantity } onChange={ this.updateQuantity } />
							</div>
							<p className='black-80 i ma0 pa0 nt2 f6'>Minimum order of 5.</p>
						</div>
						<div className='tr mt4'>
							<span className={ 'mb3 black-50 fadeinUp db'} key={ quantity }>Subtotal: ${ this.getPrice() * quantity }</span>
							<BlackButton title="Add to Cart" disabled={ !this.valid() } onClick={ this.addToCart } />
						</div>
					</form>
				</div>
			</div>
		)
	}
}


class PlaceCardForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...default_states['place_cards'],
		};
		if(this.props.product.customizable) {
			this.state.quantity = 0;
		}
	}
	getPrice = () => {
		const { color } = this.state;
		const product = this.props.product;
		if(isPremium(product.material, color)) {
			return product.premium_price;
		}
		return product.base_price;
	}
	updateField = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}
	updateNames = (event) => {
		let value = event.target.value.split(',');
		this.setState({
			'names' : value,
			'quantity': value.length
		});
	}
	validate = (event) => {
		let value = event.target.value.split(',');
		value = value.filter(name => name.trim());
		this.setState({
			'names' : value,
			'quantity': value.length
		});
	}
	valid = () => {
		const color = this.state.color;
		const names = this.state.names;
		if(this.props.product.customizable) {
			return color !== "Choose Color" && names.length > 0;
		}
		return color !== "Choose Color";
	}
	updateQuantity = (event) => {
		let quantity = parseInt(event.target.value);
		if(isNaN(quantity)) {
		  quantity = 0;
		}
		this.setState({ quantity: quantity })
	}
	addToCart = (e) => {
		e.preventDefault();
		// const quantity = this.state.names.length;
		if(this.valid()) {
			let product = {
				material: this.props.product.material,
				color: this.state.color,
				quantity: this.state.quantity,
				price: this.getPrice(),
				comparator: cart.uuid()
			}
			if(this.props.product.customizable) {
				product.names = this.state.names;
			}
			// product.comparator = _snake(this.props.product.name + this.state.material + this.state.color + this.state.names[0] + this.state.names[this.state.names.length - 1]);
			this.props.add(product);
			this.clearForm();
		} else {
			messages.show('Please select a quantity and color before adding this to your cart.');
		}
	}
	clearForm = () => {
		this.setState({
			names: [],
			quantity: 1
		})
	}
	render() {
		const product = this.props.product;
		const colors = product.colors;
		const quantity = this.state.quantity;
		const label = quantity ? "Names (" + quantity + ")" : "Names";
		const rigid_dimensions = product.fields.slug === "/two_tone_chic_place_card" || product.fields.slug === "/sweet_honeycomb_acrylic_place_card" || product.fields.slug === "/sweet_honeycomb_wood_place_card";
		return (
			<div>
				<ProductDescription product={ product } price={ this.getPrice() }>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Dimensions</h3>
						<p className='pa0 ma0'>{ product.dimensions_string }</p>
						{ product.customizable && !rigid_dimensions &&
							<p className='ma0 mt2 pa0 f6 i'>Product size varies per name.</p>
						}
					</div>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Material</h3>
						<p className='pa0 ma0'>1/8" Acrylic</p>
					</div>
				</ProductDescription>
				<div className='bt b--black-05 pt4 mb4 mb0-ns'>
					<form>
						<div className='cf'>
							<div className='w-100 w-75-ns fl'>
								<FormSelect name='color' label="Color" items={ [{ value: 'Choose Color', label: 'Choose Color' }].concat( buildList(colors) ) } value={ this.state.color } onChange={ this.updateField } />
							</div>
						</div>
						{ product.customizable &&
							<div className='cf'>
								<FormList label={ label } value={ this.state.names } onChange={ this.updateNames } validate={ this.validate } />
							</div>
						}
						{ !product.customizable &&
							<div className='cf'>
								<div className='w-30 w-20-ns fl'>
									<FormInput name='quantity' label="Quantity" value={ this.state.quantity } onChange={ this.updateQuantity } />
								</div>
							</div>
						}
						<div className='tr'>
							<span className={ 'mb3 black-50 fadeinUp ' + ( this.state.color === 'Choose Color' ? ' dn' : ' db') } key={ quantity }>Subtotal: ${ this.getPrice() * quantity }</span>
							<BlackButton title="Add to Cart" disabled={ !this.valid() } onClick={ this.addToCart } />
						</div>
					</form>
				</div>
			</div>
		)
	}
}

class TableNumberForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...default_states['table_numbers'],
		};
		const product = props.product;
		const colors = product.colors;
		if(colors.length === 1) {
			this.state.color = colors[0];
		}
	}
	getPrice = () => {
		const { color } = this.state;
		const product = this.props.product;
		if(isPremium(product.material, color)) {
			return product.premium_price;
		}
		return product.base_price;
	}
	updateField = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}
	updateRange = (event) => {
		const name = event.target.name;
		let value = parseInt(event.target.value);
		if(isNaN(value)) {
		  value = 0;
		}
		this.setState({ [name]: value })
	}
	valid = () => {
		const color = this.state.color;
		const starting = parseInt(this.state.starting_number);
		const ending = parseInt(this.state.ending_number);
		return color !== "Choose Color" && starting < ending;
	}
	addToCart = (e) => {
		e.preventDefault();
		if(this.valid()) {
			const product = {
				material: this.props.product.material,
				color: this.state.color,
				range: [this.state.starting_number, this.state.ending_number],
				quantity: (this.state.ending_number - this.state.starting_number) + 1,
				price: this.getPrice()
			}
			product.comparator = _snake(this.props.product.name + this.props.product.material + this.state.color + this.state.starting_number + this.state.ending_number);
			this.props.add(product);
			this.clearForm();
		} else {
			messages.show('Please select a quantity and color before adding this to your cart.');
		}
	}
	clearForm = () => {
		this.setState({
			starting_number: 1,
			ending_number: 15,
		})
	}
	render() {
		const product = this.props.product;
		const colors = product.colors;
		const quantity = (this.state.ending_number - this.state.starting_number) + 1;
		return (
			<div>
				<ProductDescription product={ product } price={ this.getPrice() }>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Dimensions</h3>
						<p className='pa0 ma0'>{ product.dimensions_string }</p>
					</div>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Material</h3>
						<p className='pa0 ma0'>1/8" Acrylic</p>
					</div>
				</ProductDescription>
				<div className='bt b--black-05 pt4 mb4 mb0-ns'>
					<form>
						<div className='cf'>
							<div className='w-100 w-two-thirds-ns fl'>
								<FormSelect name='color' label="Color" items={ [{ value: 'Choose Color', label: 'Choose Color' }].concat( buildList(colors) ) } value={ this.state.color } onChange={ this.updateField } disabled={ colors.length === 1 }/>
							</div>
						</div>
						<div className='cf'>
							<div className='w-100 w-third-ns pr3-ns fl'>
								<FormInput name='starting_number' label="Starting Number" value={ this.state.starting_number } onChange={ this.updateRange } />
							</div>
							<div className='w-100 w-third-ns pl3-ns fl'>
								<FormInput name='ending_number' label="Ending Number" value={ this.state.ending_number } onChange={ this.updateRange } />
							</div>
						</div>
						<div className='tr'>
							<span className={ 'mb3 black-50 fadeinUp ' + ( this.state.color === 'Choose Color' ? ' dn' : ' db') } key={ quantity }>Subtotal: ${ this.getPrice() * quantity }</span>
							<BlackButton title="Add to Cart" disabled={ !this.valid() } onClick={ this.addToCart } />
						</div>
					</form>
				</div>
			</div>
		)
	}
}

class DrinkStirrerForm extends React.Component {
	constructor(props) {
		super(props);
		const product = props.product;
		this.state = {
			...default_states['drink_stirrers'],
		};
		if(product.customizable) {
			this.state.customization = '';
		}
	}
	getProduct = () => {
		const products = this.props.products;
		const base = this.props.product;
		const state = this.state;
		const prod = _find(products, (prod) => {
			return prod.stirrer_size === state.size && prod.name === base.name;
		})
		return prod;
	}
	getPrice = () => {
		const prod = this.props.product;
		return prod.base_price;
	}
	updateField = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}
	updateQuantity = (event) => {
		let quantity = parseInt(event.target.value);
		if(isNaN(quantity)) {
		  quantity = 0;
		}
		this.setState({ quantity: quantity })
	}
	valid = () => {
		let { color, size, quantity} = this.state;
		if(this.props.product.customizable) {
			return color !== "Choose Color" && quantity > 0 && size !== '' && this.state.customization != '';
		}
		return color !== "Choose Color" && quantity > 0 && size !== '';
	}
	addToCart = (e) => {
		e.preventDefault();
		const p = this.getProduct();
		if(this.valid()) {
			const product = {
				...this.state,
				price: this.getPrice(),
				material: this.props.product.material,
				id: p.fields.airtable_id
			}
			product.comparator = this.props.product.name + this.props.product.material + this.state.color + this.state.size;
			if(this.props.product.customizable) {
				product.comparator = product.comparator + this.state.customization;
			}
			product.comparator = _snake(product.comparator);
			this.props.add(product);
			this.clearForm();
		} else {
			messages.show('Please select a quantity and color before adding this to your cart.');
		}
	}
	clearForm = () => {
		this.setState({

		})
	}
	render() {
		const product = this.props.product;
		const colors = product.colors;
		const chosen = this.state.size;
		return (
			<div>
				<ProductDescription product={ this.getProduct() } price={ this.getPrice() } description={ <p className='pa0 i f5'>Set of 6.</p> }>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Material</h3>
						<p className='pa0 ma0'>1/8" Acrylic</p>
					</div>
					<div className='pb3'>
						<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Sizes</h3>
						<p className='pa0 ma0 f6 mb2 black-80'><span className='b'>Tall</span> stirrers are sized to fit highball glasses.</p>
						<p className='pa0 ma0 f6 mb2 black-80'><span className='b'>Medium</span> stirrers are sized to fit champagne or stemless wine glasses.</p>
						<p className='pa0 ma0 f6 black-80'><span className='b'>Short</span> stirrers are sized to fit double old-fashioned glasses.</p>
					</div>
				</ProductDescription>
				<div className='bt b--black-05 pt4 mb4 mb0-ns'>
					<form>
						<div className='cf'>
							<div className='w-100 fl'>
								<FormLabel title="Stirrer Size" />
								<div className='db dib-ns'>
									<label key={ "size_tall" } htmlFor={ "size_tall" } className={'pointer mt2 dim db dib-ns pa3 mr3-ns mb3 mb0-ns br1 fadeinUp v-btm ' + (chosen === "Tall" ? ' ba b--black-90' : ' ba b--black-10')}>
										<input className='dib mr3 o-0 fixed' type="radio" id={ "size_tall" } name="size" value={ "Tall" } checked={ chosen === "Tall" } onChange={ this.updateField } />
										<img className='w2 dib' src={ "/assets/highball_glass.png" } />
										<span className='pl3 dib black-80 relative' style={{ bottom: '0.25rem' }}>Tall</span>
									</label>
								</div>
								<div className='db dib-ns'>
									<label key={ "size_medium" } htmlFor={ "size_medium" } className={'pointer mt2 dim db dib-ns pa3 mr3-ns mb3 mb0-ns br1 fadeinUp v-btm ' + (chosen === "Medium" ? ' ba b--black-90' : ' ba b--black-10')}>
										<input className='dib mr3 o-0 fixed' type="radio" id={ "size_medium" } name="size" value={ "Medium" } checked={ chosen === "Medium" } onChange={ this.updateField } />
										<img className='w2 dib' src={ "/assets/champagne_glass.png" } />
										<span className='pl3 dib black-80 relative' style={{ bottom: '0.25rem' }}>Medium</span>
									</label>
								</div>
								<div className='db dib-ns'>
									<label key={ "size_short" } htmlFor={ "size_short" } className={'pointer mt2 dim db dib-ns pa3 mr3-ns br1 fadeinUp v-btm ' + (chosen === "Short" ? ' ba b--black-90' : ' ba b--black-10')}>
										<input className='dib mr3 o-0 fixed' type="radio" id={ "size_short" } name="size" value={ "Short" } checked={ chosen === "Short" } onChange={ this.updateField } />
										<img className='w2 dib' src={ "/assets/oldfashioned_glass.png" } />
										<span className='pl3 dib black-80 relative' style={{ bottom: '0.25rem' }}>Short</span>
									</label>
								</div>
							</div>
						</div>
						<div className='cf mt4'>
							<div className='w-100 w-50-ns fl'>
								<FormSelect name='color' label="Color" items={ [{ value: 'Choose Color', label: 'Choose Color' }].concat( buildList(colors) ) } value={ this.state.color } onChange={ this.updateField } />
							</div>
						</div>
						{ product.customizable &&
							<FormTextArea desc="Minimum of two characters." name='customization' label="Custom Word" value={ this.state.customization } onChange={ this.updateField } />
						}
						<div className='cf'>
							<div className='w-30 w-20-ns fl'>
								<FormInput name='quantity' label="Quantity" value={ this.state.quantity } onChange={ this.updateQuantity } />
							</div>
						</div>
						<div className='tr pt3'>
							<span className={ 'mb3 black-50 fadeinUp ' + ( this.state.color === 'Choose Color' ? ' dn' : ' db') } key={ this.state.quantity }>Subtotal: ${ this.getPrice() * this.state.quantity }</span>
							<BlackButton title="Add to Cart" disabled={ !this.valid() } onClick={ this.addToCart } />
						</div>
					</form>
				</div>
			</div>
		)
	}
}

class CakeTopperForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			...default_states['cake_toppers'],
		};
	}
	getPrice = () => {
		const { color } = this.state;
		const product = this.props.product;
		if(isPremium(product.material, color)) {
			return product.premium_price;
		}
		return product.base_price;
	}
	updateField = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}
	updateQuantity = (event) => {
		let quantity = parseInt(event.target.value);
		if(isNaN(quantity)) {
		  quantity = 0;
		}
		this.setState({ quantity: quantity })
	}
	valid = () => {
		const quantity = this.state.quantity;
		const color = this.state.color;
		const customization = this.state.customization;
		if(this.props.product.customizable) {
			return (quantity > 0) && color !== "Choose Color" && customization.length > 1;
		}
		return (quantity > 0) && color !== "Choose Color";
	}
	addToCart = (e) => {
		e.preventDefault();
		if(this.valid()) {
			const product = {
				...this.state,
				material: this.props.product.material,
				price: this.getPrice()
			}
			product.comparator = _snake(this.props.product.name + this.props.product.material + this.state.color + this.state.customization);
			this.props.add(product);
			this.clearForm();
		} else {
			messages.show('Please select a quantity and color before adding this to your cart.');
		}
	}
	clearForm = () => {
		this.setState({
			quantity: 1,
			customization: ''
		})
	}
	render() {
		const product = this.props.product;
		const colors = product.colors;
		return (
			<div>
				<ProductDescription product={ product } price={ this.getPrice() }>
					<div>
						<div className='pb3'>
							<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Dimensions</h3>
							{ product.customizable &&
								<p className='black-80 ma0 pt0 f6'>Product size varies by name. Sized to fit perfectly on single-tiered to multi-tiered cakes.</p>
							}
							{ !product.customizable &&
								<p className='pa0 ma0'>{ product.dimensions_string }</p>
							}
						</div>
						<div className='pb3'>
							<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Stem Height</h3>
							<p className='pa0 ma0'>3.75"</p>
						</div>
						<div className='pb3'>
							<h3 className='ttu tracked black-50 fw4 f6 db mb2'>Material</h3>
							<p className='pa0 ma0'>1/8" Acrylic</p>
						</div>
					</div>
				</ProductDescription>
				<div className='bt b--black-05 pt4 mb4 mb0-ns'>
					<form>
						<div className='w-100 w-50-ns db'>
							<FormSelect name='color' label="Color" items={ [{ value: 'Choose Color', label: 'Choose Color' }].concat( buildList(colors) ) } value={ this.state.color } onChange={ this.updateField } />
						</div>
						{ product.customizable &&
							<FormTextArea desc={ product.instructions } name='customization' label="Custom Name(s)" value={ this.state.customization } onChange={ this.updateField } />
						}
						<div className='cf'>
							<div className='w-100 w-20-ns fl'>
								<FormInput name='quantity' label="Quantity" value={ this.state.quantity } onChange={ this.updateQuantity } />
							</div>
						</div>
						<div className='tr'>
							<span className={ 'mb3 black-50 fadeinUp ' + ( this.state.color === 'Choose Color' ? ' dn' : ' db') } key={ this.state.quantity }>Subtotal: ${ this.getPrice() * this.state.quantity }</span>
							<BlackButton title="Add to Cart" disabled={ !this.valid() } onClick={ this.addToCart } />
						</div>
					</form>
				</div>
			</div>
		)
	}
}




class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeImage: 1,
			guide_visible: false
		}
	}
	componentDidMount() {
		events.subscribe('cartSize', 'productPage', this);
	}
	componentWillUnmount = () => {
		events.unsubscribe('cartSize', 'productPage');
	}
	showGuide = () => {
		this.setState({ guide_visible: true })
	}
	changeImage = (idx) => {
		this.setState({ activeImage: idx, guide_visible: false })
	}
	renderProductPage = (category) => {
		const product = this.props.data.product;
		const products = this.props.data.products.edges.map(({node}) => node );
		switch(category) {
			case 'cake_toppers':
				return ( <CakeTopperForm product={ product } add={ this.addToCart } /> );
			case 'table_numbers':
				return ( <TableNumberForm product={ product } add={ this.addToCart } /> );
			case 'place_cards':
				return ( <PlaceCardForm product={ product } add={ this.addToCart } /> );
			case 'drink_stirrers':
				return ( <DrinkStirrerForm products={ products } product={ product } add={ this.addToCart } /> );
			case 'menus':
				return ( <MenuForm products={ products } product={ product } add={ this.addToCart } /> );
			case 'signs':
				return ( <SignForm products={ products } product={ product } add={ this.addToCart } /> );
			default:
		}		return ( <CakeTopperForm product={ product } add={ this.addToCart } /> );
	}
	renderShipping = () => {
		const remaining = 100 - (cart.sum() / 100);
		const mapped = cart.mapped(this.props.data.products.edges.map(item => item.node));
		const has_sign = mapped.find(prod => prod.category === 'signs');
		const blurb = false ? "$10 standard shipping" : "free standard shipping";
		if(remaining > 0) {
			return (
				<FreeShippingSlim>
					Order ${ remaining } more and qualify for <span className='b'>{ blurb }*</span>.
				</FreeShippingSlim>
			)
		}
		return (
			<FreeShippingSlim>
				Good news! You qualify for <span className='b'>{ blurb }*</span>.
			</FreeShippingSlim>
		)
	}
	addToCart = (o) => {
		const product = {
			category: this.props.data.product.fields.category_slug,
			name: this.props.data.product.name,
			id: this.props.data.product.fields.airtable_id,
			...o
		}
		cart.add(product);
		messages.show('ADDED_TO_CART');
	}
	render() {
		const data = this.props.data;
		const product = this.props.data.product;
		const material = product.material.toLowerCase();
		const crumbs = [{ vanity: "Shop", url: "/shop" }, { vanity: product.category, url: "/shop/" + product.fields.category_slug}];
		// This is such a dirty, stupid hack.
		const first_color = product.colors[0];
		return (
			<Layout>
				<div className='mv4 mv5-ns ph3 ph4-ns cf'>
					<Breadcrumbs items={crumbs} />
					<div className='w-100 pt4 pt3-ns cf'>
						<div className='w-100 w-50-ns mb4 mb0-n bb b--black-05 bn-ns pb4 pb0-ns fl'>
							{ this.state.guide_visible &&
								<img src={ '/assets/' + product.color_guide + '_color_guide.jpg' } />
							}
							{ !this.state.guide_visible &&
								<ProductImage product={ product } idx={ this.state.activeImage } />
							}
							<div className='pt3'>
								<Thumbnails
									product={ product }
									activeIndex={ this.state.activeImage }
									onClick={ this.changeImage }
									showGuide={ this.showGuide }
								/>
							</div>
						</div>
						<div className='w-100 w-50-ns ph4-ns fl'>
							{ this.renderProductPage(product.fields.category_slug) }
							<div className='pt4 pt5-ns tl tr-ns'>
								{ this.renderShipping() }
								<p className='f7 pa0 ma0 mt2'><Link className='blue no-underline dim' to="/faq">*$10 for orders with backdrop signs.</Link></p>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		);
	}
};

export const query = graphql`
	query ProductQuery($airtable_id: String!, $category_slug: String!) {
		categories: allAirtableCategories {
			totalCount
			edges {
				node {
					name
					slug
				}
	  		}
		}
		products: allAirtableProducts( filter: { fields: { category_slug: {eq: $category_slug }}}) {
			totalCount
			edges {
				node {
					name
					item_length
					item_width
					stirrer_size
					instructions
					fields {
						category_slug
						airtable_id
					}
				}
	  		}
		}
		product: airtableProducts(fields: { airtable_id: { eq: $airtable_id } }) {
			name
			category
			base_price
			premium_price
			customizable
			material
			colors
			color_guide
			instructions
			item_length
			item_width
			item_height
			dimensions_string
			image_count
			fields {
				slug
				airtable_id
				path
				category_slug
			}
		}
	}
`;

export default Product;