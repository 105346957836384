import React from "react"
import { Link } from "gatsby"
import _range from "lodash/range"
import ProductImage from "../components/product-image"


class Thumbnails extends React.Component {
	renderGuide() {
		const guide = this.props.product.color_guide.toLowerCase();
		const has_guide = this.props.product.colors.length > 1;
		if(!has_guide) {
			return null;
		}
		return (
			<li onClick={ this.props.showGuide } className='pointer dim dib w-10 mr3'>
				<img src={ '/assets/' + guide + '_color_guide.png' } />
			</li>
		)
	}
	render() {
		const active = this.props.activeIndex;
		const image_count = this.props.product.image_count;
		const has_guide = this.props.product.color_guide !== "none";
		const crumbs = _range(1, image_count + 1).map(idx => {
			// if(idx === active) {
			// 	return null;
			// }
			return (
				<li onClick={ this.props.onClick.bind(this, idx) } className='pointer dim dib w-10 mr3' key={ idx }>
					<ProductImage product={ this.props.product } idx={ idx } />
				</li>
			)
		})
		return (
			<ol className='pa0 ma0 list'>
				{ crumbs }
				{ has_guide && this.renderGuide() }
			</ol>
		)
	}
}

export default Thumbnails;
